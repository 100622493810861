<template>
  <div class="admin-discountInfo">
    <div class="title">
      <span style="font-size: 18px">折扣活动详情</span>
      <span
        v-if="infoData.status_txt == '接下来的活动'"
        style="
          font-size: 12px;
          font-weight: 600;
          color: #ee4d2d;
          padding: 2px 4px;
          margin-left: 10px;
        "
        >接下来的活动</span
      >
      <span
        v-if="infoData.status_txt == '进行中的活动'"
        style="
          font-size: 12px;
          font-weight: 600;
          color: #55cc77;
          padding: 2px 4px;
          margin-left: 10px;
        "
        >进行中的活动</span
      >
      <span
        v-if="infoData.status_txt == '已过期'"
        style="
          font-size: 12px;
          font-weight: 600;
          color: #666;
          padding: 2px 4px;
          margin-left: 10px;
        "
        >已过期</span
      >
    </div>
    <div class="cell1">
      <div style="font-size: 18px">促销商品</div>
      <div
        style="
          font-size: 12px;
          background: #fafafa;
          padding: 10px;
          margin-top: 10px;
        "
      >
        <span style="margin-right: 20px"
          >状态：
          <span v-if="infoData.status_txt == '接下来的活动'" class="tag"
            >接下来的活动</span
          >
          <span v-if="infoData.status_txt == '进行中的活动'" class="tag1"
            >进行中的活动</span
          >
          <span v-if="infoData.status_txt == '已过期'" class="tag2"
            >已过期</span
          >
        </span>
        <span style="margin-right: 20px"
          >折扣类型：{{ infoData.discount_type_txt }}</span
        >
        <span>活动期限：{{ infoData.time }} (GMT+08)</span>
      </div>
    </div>
    <div class="cell1">
      <div style="font-size: 14px; padding: 10px 0 20px">商品排名</div>
      <div class="cell2-list">
        <div class="list-head">
          <div style="width: 5%">排名</div>
          <div style="width: 25%">商品</div>
          <div style="width: 15%">规格</div>
          <div style="width: 10%">原价</div>
          <div style="width: 10%">折扣</div>
          <div style="width: 10%">折扣价格</div>
          <div style="width: 10%; display: flex; align-items: center">
            <span style="margin-right: 6px"> 售出件数</span>
            <div>
              <div
                :class="
                  field == 'number' && order == 1 ? 'triangleC' : 'triangleT'
                "
                @click="handleSort('number', 1)"
              ></div>
              <div
                :class="
                  field == 'number' && order == 2 ? 'triangleD' : 'triangleB'
                "
                @click="handleSort('number', 2)"
              ></div>
            </div>
          </div>
          <div style="width: 5%; display: flex; align-items: cente">
            <span style="margin-right: 6px"> 销售额</span>
            <div>
              <div
                :class="
                  field == 'actual_payment' && order == 1
                    ? 'triangleC'
                    : 'triangleT'
                "
                @click="handleSort('actual_payment', 1)"
              ></div>
              <div
                :class="
                  field == 'actual_payment' && order == 2
                    ? 'triangleD'
                    : 'triangleB'
                "
                @click="handleSort('actual_payment', 2)"
              ></div>
            </div>
          </div>
        </div>
        <div v-if="listData.list && listData.list.length != 0">
          <div
            class="list-content"
            v-for="(item, i) in listData.list"
            :key="item.id"
            :style="{ border: i == 0 ? 'none' : '' }"
          >
            <div style="width: 5%">{{ i + 1 }}</div>
            <div style="width: 25%; display: flex; font-size: 12px">
              <div>
                <el-image
                  style="width: 50px; height: 50px; margin-right: 10px"
                  :src="item.image"
                  fit="fit"
                ></el-image>
              </div>
              <div>
                <div>{{ item.title }}</div>
                <div style="font-size: 12px; margin-top: 4px; color: #b0b0b0">
                  全球商品货号：{{
                    item.goods_sku_sn ? item.goods_sku_sn : "-"
                  }}
                </div>
              </div>
            </div>
            <div style="width: 15%">{{ item.difference }}</div>
            <div style="width: 10%">RM{{ item.price }}</div>
            <div style="width: 10%">
              <span
                style="
                  border: 1px solid #ee4d2d;
                  padding: 2px 4px;
                  border-radius: 2px;
                  color: #ee4d2d;
                "
                >{{ parseInt(item.discount_amount) }}%折扣</span
              >
            </div>
            <div style="width: 10%">RM{{ item.discount_price }}</div>
            <div style="width: 10%">{{ item.number }}</div>
            <div style="width: 5%">{{ item.actual_payment }}</div>
          </div>
        </div>
        <div
          v-else
          style="
            text-align: center;
            color: #b0b0b0;
            line-height: 100px;
            font-size: 14px;
          "
        >
          暂无
        </div>
      </div>
      <div style="display: flex; justify-content: right; padding-top: 20px">
        <el-pagination
          :page-size="page_size"
          :current-page="page"
          background
          layout="prev, pager, next"
          :total="listData.total"
          @current-change="pageChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { discountDetails, discountOrderList } from "@/api/admin.js";
export default {
  data() {
    return {
      infoData: "",
      listData: "",
      page: 1,
      page_size: 10,
      field: "number",
      order: "0",
    };
  },
  created() {
    this.getInfo();
    this.getList();
  },
  methods: {
    getInfo() {
      discountDetails({
        id: sessionStorage.ADMIN_ACTIVITYID,
      }).then((res) => {
        if (res.code == 1) {
     
          this.infoData = res.data;
          this.infoData.time =
            this.formatTime(res.data.start_time) +
            "-" +
            this.formatTime(res.data.end_time);
        }
      });
    },
    getList() {
      discountOrderList({
        discount_id: sessionStorage.ADMIN_ACTIVITYID,
        field: this.field,
        order: this.order,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        if (res.code == 1) {
          this.listData = res.data;
        }
      });
    },
    pageChange(index) {
      this.page = index;
      this.getList();
    },
    // 排序
    handleSort(type, val) {
      this.field = type;
      this.order = val;
      this.$forceUpdate();
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.admin-discountInfo {
  //   width: 1400px;
  margin: 0 auto;
  padding-bottom: 40px;
  .cell1 {
    padding: 20px;
    background: #fff;
    border-radius: 2px;
    margin-top: 20px;
    .tag1 {
      background: #eaf9ef;
      color: #55cc77;
      display: initial;
      padding: 2px 4px;
      border-radius: 2px;
    }
    .tag {
      background: #fff1f0;
      color: #ee4d2d;
      display: initial;
      padding: 2px 4px;
      border-radius: 2px;
    }
    .tag2 {
      background: #eeeeee;
      color: #666;
      display: initial;
      padding: 2px 4px;
      border-radius: 2px;
    }
    .cell1-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      // justify-content: space-between;
      font-size: 12px;
    }
    .cell2-list {
      border: 1px solid #eee;
      border-radius: 4px;
      .list-head {
        display: flex;
        justify-content: space-between;
        background: #f6f6f6;
        padding: 10px 20px;
        font-size: 12px;
      }
      .list-content {
        display: flex;
        justify-content: space-between;
        padding: 40px 0;
        margin: 0 20px;
        font-size: 12px;
        align-items: center;
        border-top: 1px solid #eee;
      }
    }
  }
}
</style>